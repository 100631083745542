import React, { useMemo, useState } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { Layout } from "../components/layout/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Slides } from "../components/hero/Slides";
import { IS_BROWSER } from "../utils";
import { IMember } from "../types";

const LaBrigata = ({ data }) => {
  const { wpSezione } = data;
  const { title, content, teamCustomFields } = wpSezione;
  const { herogallery, opacity, team } = teamCustomFields;
  const [activeImage, setActiveImage] = useState(-1);

  const isMobile = useMemo(
    () => (IS_BROWSER ? window.innerWidth < 768 : null),
    []
  );

  const handleMouseEnter = (index: number) => {
    setActiveImage(index);
  };

  const handleMouseLeave = () => {
    setActiveImage(-1);
  };

  const DesktopView = useMemo(
    () => (
      <div className="bg-tertiary">
        <div className="container pt-5 pb-5">
          {team.map((member: IMember, index: number) => {
            const isSelected = activeImage === index;
            return (
              <div className="row bg-white mb-5 mt-5" key={index}>
                <div
                  onMouseOver={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  className="px-0 col-12 col-md-7"
                >
                  <GatsbyImage
                    className={isSelected ? "d-none" : ""}
                    alt={member.name}
                    loading="eager"
                    image={
                      member.photo.localFile.childImageSharp.gatsbyImageData
                    }
                  />
                  <GatsbyImage
                    className={isSelected ? "" : "d-none"}
                    alt={member.name}
                    loading="eager"
                    image={
                      member.drawing.localFile.childImageSharp.gatsbyImageData
                    }
                  />
                </div>
                <div className="px-4 col-12 col-md-5 mt-0 mt-md-5">
                  <h1 className="text-uppercase mb-0 h2">{member.name}</h1>
                  <h2 className="h3">{member.role}</h2>
                  <div
                    className="pt-4"
                    dangerouslySetInnerHTML={{ __html: member.bio }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ),
    [activeImage, team]
  );

  const MobileView = useMemo(
    () => (
      <div className="bg-white">
        <div className="container pb-5">
          {team.map((member: IMember, index: number) => {
            return (
              <div className="row bg-white mb-3 mt-3" key={index}>
                <div className="px-0 col-12">
                  <GatsbyImage
                    alt={member.name}
                    image={
                      member.photo.localFile.childImageSharp.gatsbyImageData
                    }
                  />
                </div>
                <div className="px-4 col-12 mt-4">
                  <h1 className="text-uppercase mb-0 h2">{member.name}</h1>
                  <h2 className="h3">{member.role}</h2>
                  <div
                    className="pt-1"
                    dangerouslySetInnerHTML={{ __html: member.bio }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ),
    [team]
  );

  const TeamComponent = useMemo(() => {
    if (IS_BROWSER) {
      return isMobile ? MobileView : DesktopView;
    }
    return null;
  }, [DesktopView, MobileView, isMobile]);

  return (
    <main>
      <Helmet title={`${title} - Ristorante A Mangiare`} defer={false} />
      <Layout headerPosition="position-absolute" className="bg-white">
        <Slides
          title={title}
          content={content}
          opacity={opacity}
          images={herogallery}
        />
        {TeamComponent}
      </Layout>
    </main>
  );
};

export default LaBrigata;

export const data = graphql`
  query {
    wpSezione(slug: { eq: "la-brigata" }) {
      title
      content
      teamCustomFields {
        herogallery {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        opacity
        team {
          bio
          name
          role
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, aspectRatio: 1)
              }
            }
          }
          drawing {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, aspectRatio: 1)
              }
            }
          }
        }
      }
    }
  }
`;
